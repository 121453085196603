import {on} from 'delegated-events'

on('click', '.js-reveal-hidden-topics', function (event) {
  const button = event.target as HTMLElement
  const container = button.closest<HTMLElement>('.js-hidden-topics-container')!
  const hiddenTopics = container.querySelector('.js-hidden-topics')

  if (hiddenTopics) {
    /* eslint-disable-next-line github/no-d-none */
    hiddenTopics.classList.remove('d-none')
    button.remove()
  }
})
